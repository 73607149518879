.content-page {
  @include sidebar-left-layout;

  h1 {
    margin-bottom: $margin-24;

    @include media-breakpoint-up(lg) {
      margin-bottom: $margin-16;
    }
  }

  main {
    a,
    p,
    dl,
    ol {
      @include merriweather-regular;
      font-size: $font-size-17;
      line-height: $font-size-28;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
        line-height: $font-size-26;
      }
    }

    .page-content {
      margin-bottom: $margin-24;


      h1 {
        @include archivo-bolder;
        font-size: $font-size-24;
        margin-bottom: $margin-12;
        line-height: $font-size-32;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h2 {
        font-size: $font-size-24;
        margin-bottom: $margin-12;
        line-height: $font-size-34;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
          line-height: $font-size-32;
        }
      }

      h3 {
        font-size: $font-size-20;
        margin-bottom: $margin-12;
        line-height: $font-size-30;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h4 {
        @include archivo-bold;
        font-size: $font-size-20;
        margin-bottom: $margin-12;
        line-height: $font-size-28;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h5 {
        font-size: $font-size-15;
        margin-bottom: $margin-12;
        line-height: $font-size-26;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h6 {
        @include archivo-regular;
        font-size: $font-size-16;
        margin-bottom: $margin-12;
        line-height: $font-size-26;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      ul {
        padding-left: 1.35rem;

        li {
          @include merriweather-regular;
          font-size: $font-size-17;
          margin-bottom: $margin-16;
          line-height: $font-size-28;

          @include media-breakpoint-up(md) {
            line-height: $font-size-30;
          }

          &::marker {
            line-height: 1rem;
            font-size: 1.75rem;
          }
        }
      }

      ol {
        padding: 0;
        list-style: none;
        counter-reset: kl-counter;

        li {
          @include merriweather-regular;
          font-size: $font-size-17;
          margin-bottom: $margin-16;
          line-height: $font-size-28;
          counter-increment: kl-counter;

          @include media-breakpoint-up(md) {
            line-height: $font-size-30;
          }

          &:before {
            @include merriweather-bold;
            margin-right: 5px;
            content: counter(kl-counter) ". ";
          }
        }
      }
    }
  }
}
