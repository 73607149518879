// named colors
$c--white: #FFFFFF;
$c--black: #000000;
$c--cod-gray: #111111;
$c--science-blue: #0069CA;
$c--red: #FF0209;
$c--turbo: #FFE604;
$c--yellow: #FFFF0E;
$c--silver: #CCCCCC;
$c--malibu: #5FB2FF;
$c--resolution-blue: #002481;
$c--indigo: #4B55CE;
$c--alabaster: #F7F7F7;
$c--prussian-blue: #001856;
$c--tundora: #4A4A4A;
$c--silver-chalice: #A5A5A5;
$c--alto: #D9D9D9;
$c--wild-sand: #F5F5F5;
$c--smalt: #003692;
$c--mercury: #E4E4E4;
$c--blue-chill: #0D9F93;
$c--ceruleanapprox: #0693E3;

// overrides
$body-bg: $c--white;
$body-color: $c--cod-gray;
$primary: $c--science-blue;
$support: $c--red;
$support2: $c--yellow;
