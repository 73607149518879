// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";
// colors
@import "colors";
// fonts
@import "fonts";

// 2. Include any default variable overrides here
$container-max-widths: (
        sm: 768px,
        md: 992px,
        lg: 1112px
);

$grid-columns: 18;
$grid-gutter-width: 2rem;
$grid-border-style: 1px solid $c--silver;

$nav-underline-border-width: 4px;

// font size
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-10: $font-size-base * 0.625;
$font-size-11: $font-size-base * 0.688;
$font-size-12: $font-size-base * 0.75;
$font-size-13: $font-size-base * 0.813;
$font-size-14: $font-size-base * 0.875;
$font-size-15: $font-size-base * 0.938;
$font-size-16: $font-size-base;
$font-size-17: $font-size-base * 1.063;
$font-size-18: $font-size-base * 1.125;
$font-size-20: $font-size-base * 1.25;
$font-size-22: $font-size-base * 1.375;
$font-size-23: $font-size-base * 1.438;
$font-size-24: $font-size-base * 1.5;
$font-size-26: $font-size-base * 1.625;
$font-size-28: $font-size-base * 1.75;
$font-size-30: $font-size-base * 1.875;
$font-size-32: $font-size-base * 2;
$font-size-34: $font-size-base * 2.125;
$font-size-36: $font-size-base * 2.25;
$font-size-38: $font-size-base * 2.375;
$font-size-40: $font-size-base * 2.5;
$font-size-42: $font-size-base * 2.625;

//// heading h1-h6
//$h1-font-size: $font-size-32;
//$h2-font-size: $font-size-24;
//$h3-font-size: $font-size-20;
//$h4-font-size: $font-size-18;
//$h5-font-size: $font-size-15;
//$h6-font-size: $font-size-base;

$border-color: $c--silver;
$input-btn-border-width: 2px;

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// margins
$margin-0: 0;
$margin-6: 6px;
$margin-8: 8px;
$margin-12: 12px;
$margin-16: 16px;
$margin-18: 18px;
$margin-24: 24px;
$margin-30: 30px;
$margin-32: 32px;
$margin-36: 36px;
$margin-40: 40px;
$margin-42: 42px;
$margin-48: 48px;
$margin-56: 56px;

// pagination
$pagination-padding-y: .375rem;
$pagination-padding-x: 1rem;
$pagination-font-size: $font-size-18;
$pagination-color: $primary;
$pagination-border-radius: 0;
$pagination-border-width: 2px;
$pagination-border-color: $c--white;
$pagination-focus-color: $primary;
$pagination-focus-box-shadow: none;
$pagination-hover-color: $primary;
$pagination-hover-bg: $c--white;
$pagination-hover-border-color: $primary;
$pagination-border-radius-sm: 0;
$pagination-border-radius-lg: 0;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";

// 4. Include any default map overrides here
$theme-colors: map-merge(
                (
                        "primary": $primary,
                        "support": $support,
                        "indigo": $c--indigo
                ),
                $theme-colors
);

$link-decoration: none;
$link-hover-decoration: underline;

$offcanvas-vertical-height: 100%;
$offcanvas-horizontal-width: 100%;
$offcanvas-horizontal-height: 100%;

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/transitions";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/forms/labels";
@import "bootstrap/scss/forms/form-check";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/pagination";
//@import "forms/input-group";
//@import "forms/validation";

@import "bootstrap/scss/nav";
@import "bootstrap/scss/offcanvas";

@import "typography";

// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

@import "kultura-liberalna";
