// mixins
@import "partials/mixins";
// buttons
@import "partials/buttons";

// page header
@import "partials/header";
// desktop navigation
@import "partials/nav-desktop";
// mobile navigation
@import "partials/nav-mobile";
// glide - sliders
@import "partials/glide";
// page footer
@import "partials/footer";
// page pagination
@import "partials/pagination";
// wordpress specific
@import "partials/wordpress";
// all shame css hacks
@import "partials/shame";

// commonly used styles
img {
  @include img-fluid;
}

// main container
.container {
  &.page {
    padding-bottom: $margin-42;

    @include media-breakpoint-up(sm) {
      padding-bottom: $margin-56;
    }
  }
}

.divider {
  margin-top: $margin-42;
  margin-bottom: $margin-8;
  border-top: 8px solid $c--black;
  @include media-breakpoint-up(sm) {
    margin-top: $margin-56;
  }

  &:before {
    left: 64%;
    top: -8px;
    z-index: 2;
    content: "";
    width: 38px;
    height: 34px;
    display: block;
    position: relative;
    background: url('../img/divider-bg.svg') no-repeat;
  }

  &.extended {
    @include media-breakpoint-up(sm) {
      left: -70px;
      width: 111%;
      margin-bottom: 0;
      position: relative;
    }
  }
}

section {
  .title {
    display: flex;
    color: $primary;
    align-items: baseline;
    margin-bottom: $margin-12;
    padding-bottom: $margin-6;
    justify-content: space-between;
    border-bottom: $grid-border-style;

    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-16;
      padding-bottom: $margin-8;
    }

    h4 {
      margin: 0;
      @include merriweather-bold;

      &.topic-number {
        @include merriweather-bold;
      }
    }
  }

  &.nr-title {
    .title {
      h4 {
        @include archivo-bold;
        font-size: $font-size-18;
        line-height: $font-size-20;
        @include media-breakpoint-up(sm) {
          line-height: $font-size-26;
        }
      }
    }
  }
}

iframe {
  width: 100%;
  min-height: 280px;
  @include media-breakpoint-up(sm) {
    min-height: 360px;
  }
}

.borderless {
  border: 0 !important;

  &::after {
    border: 0 !important;
    display: none !important;
  }
}

.sidebar-widgets {
  margin: 20px 0;
}

// ads
.advertisement-slot {
  margin: 20px 0;

  h6 {
    @include archivo-bold;

    margin-bottom: 0;
    color: $c--silver;
    font-size: $font-size-11;
    text-transform: uppercase;
    line-height: $font-size-24;
  }

  &.home-slot-1 {
    &:before {
      content: '';
      display: block;
      padding-top: 20px;
      border-top: $grid-border-style;
    }
  }

  a:not(:last-of-type){
    margin-bottom: 14px;
  }
}

// page widgets
@import "pages/_widgets.scss";
