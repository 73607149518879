.newsletter {
  .title {
    color: $c--science-blue;
  }

  @include sidebar-left-layout;

  .content {
    .sign-box {
      color: $c--white;
      padding: 23px 17px;
      margin-bottom: 20px;
      background: $c--science-blue;

      @include media-breakpoint-up(lg) {
        padding: 45px 40px;
      }

      input[type=text],
      input[type=email], {
        @include archivo-bold;
        border: 0;
        width: 100%;
        font-weight: 400;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: $font-size-16;

        &::placeholder {
          color: $c--black;
        }
      }

      .btn-submit {
        @include archivo-bold;
        border: 0;
        padding: 9px 25px;
        background: $c--turbo;
        box-sizing: border-box;
        font-size: $font-size-16;

        &:hover {
          color: $c--black;
          background: lighten($c--turbo, 15);
        }
      }

      .form-check {
        margin: 15px 0 25px;

        @include media-breakpoint-up(lg) {
          margin-top: 20px;
          margin-bottom: 0;
        }

        .form-check-label {
          @include archivo-regular;
          font-size: $font-size-16;

          @include media-breakpoint-up(lg) {
            margin-left: 15px;
          }
        }
      }
    }

    p {
      font-size: $font-size-17;
      line-height: $font-size-28;

      a {
        color: $c--blue-chill;
        font-size: $font-size-17;
        line-height: $font-size-28;

        &:hover {
          color: darken($c--blue-chill, 5);
        }
      }
    }
  }
}
