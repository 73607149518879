input,
.btn,
.form-check-input,
.form-check-input[type=checkbox] {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &:focus,
  &:active,
  &:checked {
    border: 0;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

// default outline
.btn-outline-primary,
.btn-outline-primary:focus:not(:focus-visible) {
  @include archivo-bold;
  padding: 8px 14px;
  outline-offset: -2px;
  display: inline-block;
  letter-spacing: 0.002em;
  font-size: $font-size-14;
  line-height: $font-size-24;
  text-transform: uppercase;
  outline: 2px solid $primary;
  transition: all 200ms ease, all 200ms ease;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-15;
  }

  &:hover, &:focus {
    outline-offset: 0; // -4px inside
    outline: solid 2px;
    background: $c--white;
    color: darken($primary, 15);
    border-color: darken($primary, 15);
  }
}

// video podcasts
.btn-icon-right {
  display: flex;
  color: $primary;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-up(lg) {
    text-transform: none;
  }

  .icon {
    margin: 0 0 0 10px;
    background-size: cover;
    background-position: center;

    &.youtube {
      width: 28px;
      height: 20px;
      background-image: url('../img/watch/youtube.svg');
    }

    &.spotify {
      width: 20px;
      height: 20px;
      background-image: url('../img/watch/spotify.svg');
    }

    &.apple {
      width: 20px;
      height: 22px;
      background-image: url('../img/watch/apple.svg');
    }
  }
}

// copy icon button
.icon-copy {
  width: 22px;
  height: 26px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  background: url('../img/icon-copy.svg');
  background-size: contain;

  &:hover {
    cursor: pointer;
  }
}