.read-more-widget {
  border-top: $grid-border-style;
  border-bottom: $grid-border-style;

  margin: 4px 0 $margin-36;
  @include media-breakpoint-up(lg) {
    margin: 0 0 $margin-32;
  }

  h5 {
    color: $primary;
    margin-bottom: $margin-12;

    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-16;
    }
  }

  .h5 {
    color: $c--black;

    margin-bottom: $margin-18;
    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-16;
    }
  }

  &.slider {
    padding: $margin-12 0 $margin-24;

    @include media-breakpoint-up(sm) {
      padding: $margin-16 0 $margin-32;
    }

    // slider
    .glide {
      .glide__arrows {
        display: none;
        @include media-breakpoint-up(sm) {
          display: block;
        }

        .glide__arrow {
          top: 60px;
        }
      }

      .glide__bullets {
        margin-bottom: 0;
        display: inline-flex;

        @include media-breakpoint-up(sm) {
          margin: 0;
          display: none;
        }
      }

      .glide__track {
        @include media-breakpoint-up(sm) {
          border-left: $grid-border-style;
          border-right: $grid-border-style;
        }

        .glide__slides {
          padding-left: 0;
        }
      }

      .glide__bullets {
        margin-top: $margin-24;
      }

      .glide__slide {
        margin: 0;
        height: auto;
        border-bottom: 0;
        align-items: start;
        padding: 0 $margin-12 $margin-12;
        border-right: $grid-border-style;

        &:last-child {
          border-right: 0;
          padding-bottom: $margin-16;
        }

        &:first-child {
          padding-left: 0;

          @include media-breakpoint-up(sm) {
            padding-left: $margin-12;
          }
        }

        img {
          width: 100%;
          margin-bottom: $margin-12;
          @include media-breakpoint-up(sm) {
            max-width: initial;
          }
        }

        a {
          display: inline-block;
        }

        ul {
          padding: 0;
        }
      }
    }
  }

  &.single {
    display: flex;
    padding: $margin-12 0 $margin-24;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
      padding: $margin-16 0 $margin-32;
    }

    .content {
      flex: 1;
      margin-right: 20px;

      a {
        display: inline-block;
      }
    }

    .image {
      flex: 0 0 auto;
      align-self: center;

      img {
        height: auto;
        display: block;
        max-width: 120px;

        @include media-breakpoint-up(sm) {
          max-width: 240px
        }
      }
    }
  }
}

.newsletter-widget {
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;
  box-sizing: border-box;
  border: 1px solid $primary;

  @include media-breakpoint-up(sm) {
    padding: 20px 60px;
    margin-bottom: 40px;
  }

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    width: 100%;
    display: flex;
    margin-bottom: $margin-12;

    input {
      @include archivo-regular;

      flex: 1;
      padding: 5px 10px;
      box-sizing: border-box;
      font-size: $font-size-14;
      border: 4px solid $primary;
      border-right: none;

      @include media-breakpoint-up(sm) {
        padding: 5px 15px;
        font-size: $font-size-base;
      }

      &::placeholder {
        color: $c--black
      }
    }

    button {
      @include archivo-bold;

      color: white;
      padding: 5px 10px;
      box-sizing: border-box;
      background-color: $primary;
      border: 4px solid $primary;
      border-left: none;
      font-size: $font-size-14;
      text-transform: uppercase;

      @include media-breakpoint-up(sm) {
        padding: 5px 30px;
        font-size: $font-size-base;
      }

      &:hover {
        background: darken($primary, 15);
      }
    }
  }

  small {
    @include archivo-regular;

    color: $c--silver-chalice;
    font-size: $font-size-10;
    @include media-breakpoint-up(sm) {
      font-size: $font-size-16;
      line-height: $font-size-22;
    }
  }
}

.donation-widget {
  padding-top: 17px;
  border-top: $grid-border-style;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
  }

  .donation-box {
    padding: 20px;
    border: 2px solid $c--red;
    @include media-breakpoint-up(sm) {
      padding: 33px 44px
    }

    h6, label {
      @include archivo-regular;
      font-size: $font-size-base;
      line-height: $font-size-20;
    }

    h6 {
      margin-bottom: 20px;
    }

    .collapse label {
      padding-top: 0;
    }

    .btn-outline-support {
      @include archivo-bold;
      color: $c--black;
      font-size: $font-size-15;
      border: 2px solid $support;

      &:hover,
      &:active,
      &:focus {
        color: $c--white;
        background: $support
      }
    }

    .btn-check:checked + .btn, :not(.btn-check) + .btn:active {
      color: $c--white;
      background: $support
    }

    .form-check-input {
      &.border-support {
        border-radius: 0;
      }

      &:checked {
        outline: 0;
        box-shadow: none;
        border-color: $support;
        background-color: $support;
      }
    }

    input[type=number] {
      @include archivo-bold;
      width: 100%;
      font-size: $font-size-15;
      padding: 0.375rem 0.75rem;
      border: 2px solid $support;

      &::placeholder {
        @include archivo-regular;
      }

      &:focus {
        border-color: $support;
      }
    }

    .statute-check {
      @include archivo-regular;
      margin-left: 15px;
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-color: $support;

      a {
        @include archivo-regular;
        color: $c--black;
        font-size: $font-size-base;
      }
    }

    .btn-submit {
      @include archivo-bold;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      max-width: 400px;
      background: $support2;
      border: 2px solid $support;
      @include media-breakpoint-up(sm) {
        width: auto;
        padding-left: 65px;
        padding-right: 65px;
      }

      &:hover {
        background: lighten($support2, 15);
      }
    }

    .stripe {
      width: 146px;
      height: 40px;
      display: block;
      text-indent: -999rem;
      background: url('../img/stripe.png') no-repeat;
      background-size: contain;
    }
  }
}
