// Variables for margins
$margin-standard: 1rem;
$margin-responsive: 0 0 1rem 0;

// Common image styling
.wp-block-image {
  img {
    max-width: 100%;
    height: auto;
  }

  &.alignleft img {
    @extend .alignleft;
  }

  &.alignright img {
    @extend .alignright;
  }

  &.aligncenter img {
    @extend .aligncenter;
  }
}

// Classic Editor specific alignment
.alignleft {
  float: left !important;
  margin: 0 $margin-standard $margin-standard 0;
}

.alignright {
  float: right !important;
  margin: 0 0 $margin-standard $margin-standard;
}

.aligncenter {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

// Clearfix for parent containers
.wp-block::after {
  content: "";
  display: table;
  clear: both;
}

// Responsive design adjustments
@media (max-width: 576px) {
  .alignleft, .alignright {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    margin: $margin-responsive;
  }
}

// skip-link
.skip-link {
  display: none;
}
