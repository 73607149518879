// header
header {
  display: flex;
  margin-top: 20px;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: $margin-12;
  justify-content: space-between;
  border-bottom: 0.5rem solid $c--black;

  @include media-breakpoint-up(md) {
    margin-top: 25px;
    margin-bottom: 0;
    padding-bottom: 30px;
  }

  .logotype {
    width: 136px;
    height: 63px;
    text-indent: -999rem;
    background-size: cover;
    background: url('../img/logotype_black.svg') no-repeat;

    @include media-breakpoint-up(md) {
      width: 266px;
      height: 121px;
    }
  }

  .support {
    text-align: right;
    align-self: flex-end;

    a {
      @include archivo-bolder;
      border: 0;
      width: 115px;
      border-radius: 0;
      color: $c--white;
      align-self: initial;
      letter-spacing: 0.002em;
      font-size: $font-size-12;
      line-height: $font-size-24;

      @include media-breakpoint-up(lg) {
        width: 165px;
        margin-top: 10px;
        font-size: $font-size-15;
      }

      &:hover,
      &:focus,
      &:active,
      &:checked {
        color: $c--white;
        background: darken($support, 15);
      }
    }

    h4, h6 {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      h4, h6 {
        margin: 0;
        display: block;
      }

      h4 {
        @include merriweather-bolder;
      }

      h6 {
        @include merriweather-regular-italic;
      }
    }
  }
}
