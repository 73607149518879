.single-video {
  .youtube-video {
    margin-bottom: $margin-12;

    @include media-breakpoint-up(md) {
      margin-bottom: $margin-24;
    }

    .ratio-4x3 {
      --bs-aspect-ratio: 100%;
      @include media-breakpoint-up(md) {
        --bs-aspect-ratio: 55%; // 2x1
      }
    }
  }

  h4 {
    // override
    font-size: $font-size-18;
    margin-bottom: $margin-12;
    line-height: $font-size-26;
    @include media-breakpoint-up(sm) {
      font-size: $font-size-20;
      margin-bottom: $margin-16;
      line-height: $font-size-26;
    }
  }

  h1 {
    margin-bottom: $margin-24;
    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-32;
    }
  }

  p {
    @include merriweather-bold;
    color: $c--tundora;
    font-size: $font-size-17;
    margin-bottom: $margin-24;
    line-height: $font-size-26;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-20;
      margin-bottom: $margin-32;
      line-height: $font-size-30;
    }
  }

  .author-list {
    display: inline-block;
    font-size: $font-size-16;
    margin-bottom: $margin-24;
    line-height: $font-size-28;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-20;
      margin-bottom: $margin-32;
    }
  }

  .podcast-services {
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    //max-width: 300px;

    li {
      padding: 0;
      display: block;
      margin: 14px 0 0 0;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-right: 35px;
        display: inline-block;

        &:nth-last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .more-videos {
    .glide {
      .glide__track {
        display: grid;
      }

      .glide__slide {
        display: flex;
        padding-right: 14px;
        flex-direction: column;
        border-right: $grid-border-style;
        @include media-breakpoint-up(lg) {
          &:nth-child(3n) {
            border-right: 0;
            padding-right: 0;
          }
        }

        &:last-child {
          border-right: 0;
          padding-right: 0;
        }

        .video {
          width: 100%;
          max-width: 450px;
          position: relative;

          img {
            width: 100%;
            height: auto;
            display: block;
          }

          .icon {
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);

            i {
              opacity: 1;
              width: 4.375rem;
              height: 4.375rem;
              display: block;
              transition: all 200ms;
              background: url('../img/video_play.svg');

              &:hover {
                opacity: 0.85;
              }
            }
          }
        }

        .content {
          width: 100%;
          flex-grow: 1;
          display: flex;
          padding: $margin-12 20px $margin-32;
          //margin-bottom: 14px;
          flex-direction: column;
          background-color: $c--resolution-blue;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            border-bottom: 0;
          }

          h5 {
            &.title {
              color: $c--malibu;
              padding-bottom: $margin-6;

              @include media-breakpoint-up(sm) {
                padding-top: 0;
                margin-bottom: $margin-12;
              }

              &:after {
                content: "";
                height: 1px;
                width: 100%;
                display: block;
                margin-top: $margin-12;
                background-color: $c--white;
              }
            }
          }

          .h5 {
            color: $c--white;
            margin-bottom: $margin-24;
          }

          ul {
            padding: 0;
            list-style: none;
            margin: 0;

            li {
              a {
                color: $c--malibu;
                font-size: $font-size-16;
                line-height: $font-size-22;

                &.author-list {
                  margin: 0;
                }
              }

              &:last-of-type {
                margin-bottom: 32px;
              }
            }
          }
        }

        .topic-number {
          @include archivo-regular;
          color: $c--white;
          margin-top: auto;
          font-size: $font-size-16;
          line-height: $font-size-22;
        }
      }

      .glide__bullets {
        display: flex;
        margin-top: $margin-24;
        @include media-breakpoint-up(lg) {
          margin: 0;
          display: none;
        }
      }
    }

    .btn-outline-primary {
      margin: 0;

      &.btn-icon-right {
        padding: 10px 20px 10px 30px;

        @include media-breakpoint-up(lg) {
          padding: 10px 20px;
        }
      }
    }
  }
}
