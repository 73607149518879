.category-kultura-liberalna-za-granica,
.abroad {
  @include three-cols-with-borders;

  .articles {
    .item {
      .box {
        .content {
          padding-bottom: $margin-36;
          @include media-breakpoint-up(lg) {
            padding-bottom: $margin-32;
          }

          .title {
            &:after {
              content: '';
              height: 1px;
              width: 100%;
              display: block;
              margin-top: 12px;
              background-color: $c--silver-chalice;
            }
          }

          .h5 {
            // override
            color: $c--black;
            font-size: $font-size-18;
            margin-bottom: $margin-12;
            line-height: $font-size-26;
            @include media-breakpoint-up(lg) {
              margin-bottom: $margin-24;
            }
          }

          h5 {
            &.title {
              margin-bottom: $margin-12;
              &:after {
                display: none;
              }
            }
          }

          .topic-number {
            @include archivo-regular;
            display: block;
            margin-top: auto;
            font-size: $font-size-16;
            line-height: $font-size-22;
          }

          .author-list {
            display: inline-block;
            color: $c--science-blue;
            font-size: $font-size-16;
            line-height: $font-size-22;

            margin-bottom: $margin-36;
            @include media-breakpoint-up(lg) {
              margin-bottom: $margin-24;
            }
          }
        }
      }
    }
  }
}
