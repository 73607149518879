.events {
  @include three-cols-with-borders;

  .articles {
    .item {
      .box {
        .content {
          padding-bottom: 0;
          margin-bottom: $margin-12;
          @include media-breakpoint-up(sm) {
            margin-bottom: $margin-16;
          }

          a {
            &.event-info,
            &.place {
              @include archivo-bold();
              font-size: $font-size-16;
              line-height: $font-size-22;

              margin-bottom: $margin-24;
              @include media-breakpoint-up(sm) {
                margin-bottom: $margin-16;
              }

              &.event-date {
                margin-bottom: $margin-6;
                @include media-breakpoint-up(sm) {
                  margin-bottom: $margin-8;
                }
              }
            }

            &.place {
              margin: 0;
            }

            &.h5 {
              // override
              font-size: $font-size-18;
              line-height: $font-size-26;

              margin-bottom: $margin-24;
              @include media-breakpoint-up(sm) {
                margin-bottom: $margin-16;
              }
            }
          }
        }
      }
    }
  }
}
