.author {
  @include category-articles-layout;

  .divider {
    margin-top: 24px;
  }

  .author-bio {
    p {
      @include archivo-regular;
      margin-bottom: 0;
      color: $c--tundora;
      font-size: $font-size-16;
      line-height: $font-size-23;
    }

    img {
      width: 100%;
      margin-bottom: 20px;
      background-color: $c--malibu;
      @include media-breakpoint-up(md) {
        width: 340px;
        margin-bottom: 0
      }
    }
  }
}
