.category-wideopodcasty,
.videos {
  .articles {
    .item {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        border-right: $grid-border-style;
        &:nth-child(3n) {
          border-right: 0;
        }
      }

      .video {
        width: 100%;
        max-width: 450px;
        position: relative;

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        .icon {
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);

          i {
            opacity: 1;
            width: 4.375rem;
            height: 4.375rem;
            display: block;
            transition: all 200ms;
            background: url('../img/video_play.svg');

            &:hover {
              opacity: 0.85;
            }
          }
        }
      }

      .box {
        flex-grow: 1;
        display: flex;
        margin-bottom: 14px;
        flex-direction: column;
        border-bottom: $grid-border-style;
        @include media-breakpoint-up(lg) {
          border-bottom: 0;
          margin-bottom: 0;
        }

        .content {
          width: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin-bottom: $margin-12;
          background-color: $c--resolution-blue;
          padding: $margin-12 $margin-24 $margin-36;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            border-bottom: 0;
            padding: $margin-12 $margin-24 $margin-32;
          }

          .title {
            margin: 0;
            color: $c--malibu;
            padding: 0 0 $margin-12;
            font-size: $font-size-18;
            line-height: $font-size-26;

            &:after {
              content: '';
              height: 1px;
              width: 100%;
              display: block;
              margin-top: $margin-12;
              background-color: $c--white;
            }
          }

          .h5, h5 {
            color: $c--white;
            font-size: $font-size-18;
            line-height: $font-size-26;
          }

          .h5 {
            margin-bottom: $margin-24;
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              line-height: 1.2;

              a {
                color: $c--malibu;
                font-size: $font-size-16;

                @include media-breakpoint-up(lg) {
                  font-size: $font-size-14;
                }
              }

              &:last-of-type {
                margin-bottom: $margin-24;
              }
            }
          }
        }

        .topic-number {
          @include archivo-regular;
          color: $c--white;
          margin-top: auto;
          font-size: $font-size-16;
          line-height: $font-size-22;
        }
      }
    }

    .border-bottom {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        margin: $margin-32 0 $margin-16;
      }
    }
  }
}
