footer {
  @include archivo-regular;
  margin-top: auto;
  padding-bottom: 4rem;
  font-size: $font-size-16;
  background-color: $c--resolution-blue;

  @include media-breakpoint-up(sm) {
    padding-bottom: 0;
    background-color: $c--alabaster;
  }

  .footer-menu {
    color: $c--white;
    padding: 33px 22px 0;

    @include media-breakpoint-up(sm) {
      padding-top: 33px;
      color: $c--tundora;
      padding-bottom: 33px;

      @include media-breakpoint-up(sm) {
        margin-top: -8px;
        border-top: 8px solid $c--black;
      }
    }

    .col-18 {
      padding-bottom: 20px;
      margin-bottom: 22px;
      border-bottom: 2px solid $c--smalt;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(sm) {
        border: 0;
        margin: inherit;
        padding: inherit;
      }
    }

    .logotype {
      width: 136px;
      height: 60px;
      margin-bottom: 45px;
      background-size: cover;
      background: url('../img/logotype_white.svg') no-repeat;

      @include media-breakpoint-up(md) {
        width: 182px;
        height: 83px;
        margin-bottom: 50px;
        background: url('../img/logotype_black.svg') no-repeat;
      }
    }

    h4 {
      color: $c--malibu;
      margin-bottom: 20px;
      font-size: $font-size-18; // odstepstwo od sizingu
      line-height: $font-size-26;

      @include media-breakpoint-up(sm) {
        color: $c--black;
        margin-bottom: $margin-16;
        padding-bottom: $margin-16;
        font-size: $font-size-20; // odstepstwo od sizingu
        border-bottom: $grid-border-style;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        padding: 0;
        width: 60%;
        margin: 0 0 18px;

        @include media-breakpoint-up(sm) {
          width: 100%;
          margin: 0 0 22px;
        }

        a {
          @include archivo-regular;
          color: $c--white;
          font-size: $font-size-16;

          @include media-breakpoint-up(sm) {
            color: $c--black;
          }
        }
      }

      &.social {
        display: flex;
        margin: 0 auto;
        max-width: 420px;
        text-indent: -999rem;
        justify-content: space-between;

        li {
          flex: 1;
          display: flex;
          margin-right: 12px;
          align-items: center;
          justify-content: center;

          &:last-child {
            margin-right: 0;
          }

          a {
            width: 35px;
            height: 35px;
            display: block;
            text-align: center;

            @include media-breakpoint-up(md) {
              width: 26px;
              height: 26px;
            }

            &.facebook {
              background: url('../img/footer/mobile/facebook.svg') no-repeat;
              @include media-breakpoint-up(md) {
                background: url('../img/footer/desktop/facebook.svg') no-repeat;
              }
              background-size: contain;
            }

            &.youtube {
              background: url('../img/footer/mobile/youtube.svg') no-repeat;
              @include media-breakpoint-up(md) {
                background: url('../img/footer/desktop/youtube.svg') no-repeat;
              }
              background-size: contain;
            }

            &.instagram {
              background: url('../img/footer/mobile/instagram.svg') no-repeat;
              @include media-breakpoint-up(md) {
                background: url('../img/footer/desktop/instagram.svg') no-repeat;
              }
              background-size: contain;
            }

            &.x {
              background: url('../img/footer/mobile/x.svg') no-repeat;
              @include media-breakpoint-up(md) {
                background: url('../img/footer/desktop/x.svg') no-repeat;
              }
              background-size: contain;
            }

            &.linkedin {
              background: url('../img/footer/mobile/linkedin.svg') no-repeat;
              @include media-breakpoint-up(md) {
                background: url('../img/footer/desktop/linkedin.svg') no-repeat;
              }
              background-size: contain;
            }

            &.spotify {
              background: url('../img/footer/mobile/spotify.svg') no-repeat;
              @include media-breakpoint-up(md) {
                background: url('../img/footer/desktop/spotify.svg') no-repeat;
              }
              background-size: contain;
            }
          }
        }
      }
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    color: $c--white;
    min-height: 100px;
    text-align: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    background-color: $c--resolution-blue;

    @include media-breakpoint-up(sm) {
      flex-direction: initial;
    }

    span {
      display: block;
      @include media-breakpoint-up(sm) {
        margin-left: 20px;
      }
    }
  }
}