// default bullets styling
.glide {
  .glide__bullets {
    width: 100%;
    display: inline-flex;
    margin-top: $margin-12;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      margin-top: $margin-24;
    }

    .glide__bullet {
      padding: 0;
      line-height: 0;
      width: 0.625rem;
      cursor: pointer;
      height: 0.625rem;
      margin: 0 0.25rem;
      border-radius: 50%;
      background-color: $c--silver;
      transition: all .3s ease-in-out;
      border: 0.13rem solid transparent;
      box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, .1);

      &.glide__bullet--active {
        background-color: $primary;
      }
    }
  }

  .glide__arrows {
    .glide__arrow {
      border: 0;
      top: 20px;
      z-index: 1;
      width: 19px;
      height: 39px;
      position: absolute;
      text-indent: -999rem;

      &:hover {
        cursor: pointer;
      }

      &--prev {
        left: -3rem;
        background: url('../img/carousel-left.svg') no-repeat;
      }

      &--next {
        right: -3rem;
        background: url('../img/carousel-right.svg') no-repeat;
      }
    }
  }
}

.glide__type--row-to-slide {
  // reset slider on desktop
  @include media-breakpoint-up(sm) {
    .glide__slides {
      width: initial;
      display: block;
      list-style: none;
      position: initial;
      overflow: initial;
      flex-wrap: initial;
      white-space: nowrap;

      .glide__slide {
        width: auto;
        height: initial;
        flex-shrink: initial;
        white-space: initial;
      }
    }
  }
}

.glide__type--col-to-slide {
  .glide__slides {
    width: initial;
    display: block;
    list-style: none;
    position: initial;
    overflow: initial;
    flex-wrap: initial;
    white-space: nowrap;

    .glide__slide {
      width: auto;
      height: initial;
      flex-shrink: initial;
      white-space: initial;
    }
  }
}

.glide__type--slide-to-row {
  position: relative;

  a {
    display: block;

    &.author-item {
      display: inline;
    }
  }

  .author-list {
    display: block;
  }

  .glide__slides {
    position: initial;
    flex-direction: column;
    margin-bottom: 0.875rem;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
      border-bottom: 0;
      position: relative;
      flex-direction: initial;
    }
  }

  .glide__arrows {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}


