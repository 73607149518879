.topic-of-the-week,
.category-temat-tygodnia {
  .separator {
    .line {
      line-height: 1px;
      margin-bottom: $margin-12;
      border-bottom: $grid-border-style;

      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-16;
      }
    }
  }

  .topic {
    justify-content: center;

    .topic-title {
      h1 {
        margin: 0;
        padding: $margin-12 0;
        @include media-breakpoint-up(lg) {
          padding: 0;
          line-height: 40px;
          margin-bottom: $margin-16;
        }
      }
    }

    .number {
      color: $primary;

      h2, h4, .h2, .h4 {
        @include archivo-bold;
      }

      h2, .h2 {
        margin-top: 4px;
        font-size: $font-size-24;
        line-height: $font-size-22;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          font-size: $font-size-28;
          line-height: $font-size-26;
        }
      }

      h4 {
        margin-bottom: 12px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
        }
      }
    }
  }

  .sub-topics {
    .item {
      @include media-breakpoint-up(lg) {
        &.border-right,
        &:nth-child(1),
        &:nth-child(2) {
          border-right: $grid-border-style;
        }
      }
    }

    img {
      margin-bottom: $margin-12;
    }

    a {
      display: inline-block;
      font-size: $font-size-16;
      line-height: $font-size-22;
    }

    .author-list {
      margin-bottom: $margin-12;
    }

    .h5, h5 {
      display: block;
      font-size: $font-size-18;
      margin-bottom: $margin-12;
      line-height: $font-size-26;
    }


    ul.list-unstyled {
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-16;
      }
      li {
        a {
          margin-bottom: 0;
        }
      }
    }

    .border-bottom {
      margin-bottom: $margin-12;
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-24;
        border-bottom: $grid-border-style;
      }
    }
  }
}
