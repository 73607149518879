.support {
  .title {
    color: $support;
  }

  @include sidebar-left-layout;

  .content {
    section {
      p {
        @include archivo-regular;
        font-size: $font-size-16;
        line-height: $font-size-26;
      }

      .content {
        padding: 20px;
        margin-bottom: 30px;
        border: 2px solid $c--red;
        @include media-breakpoint-up(lg) {
          padding: 33px 44px
        }

        h6 {
          @include archivo-regular;
          margin-bottom: 20px;
          font-size: $font-size-base;
          line-height: $font-size-20;
        }
      }

      &.patronite {
        header {
          margin: 0;
          height: 64px;
          display: block;
          border-bottom: 0;
          background: $c--wild-sand url('../img/patronite.png') no-repeat left center;
          background-size: contain;

          @include media-breakpoint-up(lg) {
            background-position-x: 25px;
          }
        }

        .content {
          padding: 0;

          .subcontent {
            padding: 20px;
            @include media-breakpoint-up(lg) {
              padding: 33px 44px
            }

            ol {
              @include archivo-regular;
              padding-left: 16px;
              margin-bottom: 30px;

              > li {
                margin-bottom: 5px;
                line-height: $font-size-26;

                &::marker {
                  font-weight: $font-weight-bold;
                }
              }
            }

            .btn-support {
              @include archivo-bold;
              width: 100%;
              padding-left: 0;
              padding-right: 0;
              max-width: 400px;
              background: $c--turbo;
              border: 2px solid $support;
              @include media-breakpoint-up(lg) {
                width: auto;
                padding-left: 65px;
                padding-right: 65px;
              }

              &:hover {
                background: lighten($c--turbo, 15);
              }
            }
          }
        }
      }

      &.traditional-transfer {
        h6 {
          margin-bottom: 10px;
        }

        .text {
          max-width: 280px;
          margin-bottom: 35px;
          vertical-align: top;
          display: inline-block;
          text-decoration: underline;
          text-underline-offset: 4px;
          text-decoration-color: $support;


          @include media-breakpoint-up(lg) {
            max-width: 100%;
            vertical-align: inherit;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &.one-percent {
        .text {
          @include archivo-bold;
          color: $support;
          letter-spacing: 2px;
          vertical-align: middle;
          font-size: $font-size-32;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-24;
          }
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .donation-widget {
      border: 0;
      padding-top: 0;
      margin-bottom: 30px;
    }
  }
}
