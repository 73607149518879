.article {
  .social-share {
    h6 {
      @include archivo-bold;
      margin-bottom: $margin-12;
      color: $c--silver-chalice;

      @include media-breakpoint-up(md) {
        margin-right: $margin-16;
      }
    }

    ul {
      padding: 0;
      display: flex;
      margin: 0 auto;
      list-style: none;
      text-indent: -999rem;
      justify-content: space-between;

      li {
        flex: 1;
        display: flex;
        margin-right: 0;
        align-items: center;
        //justify-content: center;

        @include media-breakpoint-up(md) {
          margin-right: 12px;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          width: 30px;
          height: 30px;
          display: block;
          text-align: center;

          &.facebook {
            background: url('../img/share/facebook.svg') no-repeat;
            background-size: contain;
          }

          &.x {
            background: url('../img/share/x.svg') no-repeat;
            background-size: contain;
          }

          &.linkedin {
            background: url('../img/share/linkedin.svg') no-repeat;
            background-size: contain;
          }
        }
      }
    }

    &.in-text {
      display: block;
      @include media-breakpoint-up(md) {
        display: none;
      }

      ul {
        gap: 15px;
        justify-content: normal;
        margin-bottom: $margin-32;

        li {
          flex: 0;
        }
      }
    }
  }

  .social-sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;

    .top,
    .bottom {
      flex: 0 0 auto;
    }

    .bottom {
      padding-top: 10px;
      border-top: $grid-border-style;
    }

    .title {
      margin-bottom: $margin-16;
      @include media-breakpoint-up(md) {
        margin-bottom: $margin-24;
      }
    }
  }

  p {
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }

    a {
      @include merriweather-bold;
      color: $primary;
      font-size: $font-size-17;
      line-height: $font-size-28;
      text-decoration: underline;
      text-decoration-color: $c--silver;

      @include media-breakpoint-up(md) {
        line-height: $font-size-30;
        text-underline-offset: 0.25em;
      }

      &:hover {
        text-decoration-color: $primary;
      }

      &.underline-gray {
        text-underline-offset: 0.375em;
        text-decoration-color: $c--silver;

        &:hover {
          text-decoration-color: $primary;
        }
      }
    }
  }

  .col-content {
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }

    // topic number
    // mobile
    .title {
      margin-bottom: $margin-16;
      @include media-breakpoint-up(md) {
        margin-bottom: $margin-24;
      }

      h2 {
        margin-bottom: 0;
      }

      .topic-number {
        display: block;
        color: $c--silver-chalice;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    // desktop
    h6, .h6 {
      &.topic-number {
        @include archivo-regular;
        display: inline-block;
        color: $c--silver-chalice;
        margin-bottom: $margin-12;

        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }

        &.bottom {
          display: none;

          @include media-breakpoint-up(md) {
            @include archivo-regular;
            display: inline-block;
          }
        }
      }
    }

    h1 {
      &.post-title {
        margin-bottom: $margin-24;

        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }
    }

    .mobile-author {
      display: block;
      margin-bottom: $margin-12;
      @include media-breakpoint-up(md) {
        display: none;
      }

      a {
        display: block;
      }

      &.avatar {
        display: flex;
        width: fit-content;
        align-items: flex-end;
        border-bottom: $grid-border-style;
        @include media-breakpoint-up(md) {
          display: none;
        }

        .name {
          flex: 1;
          margin-bottom: 8px;
          margin-right: 20px;
        }

        img {
          //width: 80px;
          height: 80px;
          margin-bottom: -1px;
        }
      }
    }

    p {
      &.lead {
        @include merriweather-bold;

        font-size: $font-size-17;
        margin-bottom: $margin-18;
        line-height: $font-size-26;

        @include media-breakpoint-up(md) {
          float: initial;
          font-size: $font-size-20;
          margin-bottom: $margin-24;
          line-height: $font-size-30;
        }
      }
    }

    figure {
      width: 100%;

      margin-bottom: $margin-24;
      @include media-breakpoint-up(md) {
        margin-bottom: $margin-32;
      }

      &.featured-image {
        .figure-img {
          margin-bottom: 0.15rem;
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          img {
            width: 100%;
          }
        }
      }

      img {
        width: 100%;
        margin-bottom: 0.15rem;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      .wp-caption-text {
        @include archivo-regular;
        text-align: right;
        font-size: $font-size-14;
        color: $c--silver-chalice;
        line-height: $font-size-18;

        @include media-breakpoint-up(md) {
          margin-top: $margin-6;
        }
      }
    }

    // content from wp editor
    .post-content {
      margin-bottom: $margin-24;


      h1 {
        @include archivo-bolder;
        font-size: $font-size-24;
        margin-bottom: $margin-12;
        line-height: $font-size-32;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h2 {
        font-size: $font-size-24;
        margin-bottom: $margin-12;
        line-height: $font-size-34;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
          line-height: $font-size-32;
        }
      }

      h3 {
        font-size: $font-size-20;
        margin-bottom: $margin-12;
        line-height: $font-size-30;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h4 {
        @include archivo-bold;
        font-size: $font-size-20;
        margin-bottom: $margin-12;
        line-height: $font-size-28;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h5 {
        font-size: $font-size-15;
        margin-bottom: $margin-12;
        line-height: $font-size-26;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      h6 {
        @include archivo-regular;
        font-size: $font-size-16;
        margin-bottom: $margin-12;
        line-height: $font-size-26;
        @include media-breakpoint-up(md) {
          margin-bottom: $margin-16;
        }
      }

      ul {
        padding-left: 1.35rem;

        li {
          @include merriweather-regular;
          font-size: $font-size-17;
          margin-bottom: $margin-16;
          line-height: $font-size-28;

          @include media-breakpoint-up(md) {
            line-height: $font-size-30;
          }

          &::marker {
            line-height: 1rem;
            font-size: 1.75rem;
          }
        }
      }

      ol {
        padding: 0;
        list-style: none;
        counter-reset: kl-counter;

        li {
          @include merriweather-regular;
          font-size: $font-size-17;
          margin-bottom: $margin-16;
          line-height: $font-size-28;
          counter-increment: kl-counter;

          @include media-breakpoint-up(md) {
            line-height: $font-size-30;
          }

          &:before {
            @include merriweather-bold;
            margin-right: 5px;
            content: counter(kl-counter) ". ";
          }
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .read-more-after {
    // borders
    .item {
      @include media-breakpoint-down(lg) {
        &:nth-child(1),
        &:nth-child(4) {
          border-right: $grid-border-style;
        }
      }

      // desktop
      @include media-breakpoint-up(lg) {
        border-right: $grid-border-style;

        &:nth-child(2),
        &:nth-child(7) {
          border-right: 0;
        }
      }
    }

    .border-separator {
      width: 100%;
      height: 1px;
      margin: 16px 0;
      padding: 0 16px;
      background: $c--silver;

      @include media-breakpoint-up(lg) {
        margin: $margin-32 0 $margin-16;
      }
    }

    img {
      width: 100%;
      margin-bottom: $margin-12;
    }

    .author-list {
      margin-bottom: 15px;
      display: inline-block;
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px
      }
    }

    a {
      &.h5 {
        display: block;
        margin-bottom: $margin-12;
        @include media-breakpoint-up(lg) {
          margin-bottom: $margin-16;
        }

        span {
          color: $primary;
        }
      }
    }

    .more-items {
      .h5 {
        font-size: $font-size-16;
        line-height: $font-size-23;
        @include media-breakpoint-up(lg) {
          font-size: $font-size-18;
          line-height: $font-size-26;
        }
      }
    }
  }

  .col-author {
    .author {
      margin-top: $margin-32;
      padding-top: $margin-12;
      margin-bottom: $margin-24;
      border-top: $grid-border-style;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        border-top: 0;
        padding-top: 0;
        border-bottom: $grid-border-style;
      }

      .avatar {
        display: none;
        margin-bottom: $margin-12;
        border-bottom: $grid-border-style;
        @include media-breakpoint-up(lg) {
          width: 225px;
          display: block;
        }

        img {
          display: block;
          margin-left: auto;
        }
      }

      .h4 {
        @include archivo-bold;
        color: $primary;
        margin-bottom: $margin-32;
        display: inline-block;
        font-size: $font-size-20;
        line-height: $font-size-24;

        @include media-breakpoint-up(lg) {
          margin-bottom: $margin-12;
        }
      }

      p {
        @include archivo-regular;
        margin: 0;
        color: $c--tundora;
        font-size: $font-size-16;
        line-height: $font-size-23;

        @include media-breakpoint-up(lg) {
          padding-bottom: $margin-24;
        }
      }
    }
  }
}
