// nawigacja glowna
.nav-desktop {
  display: none;

  @include media-breakpoint-up(sm) {
    @include archivo-regular;
    top: 0;
    z-index: 1020;
    display: flex;
    position: sticky;
    text-align: center;
    justify-content: center;
    font-size: $font-size-12;
    margin-bottom: $margin-16;
    text-transform: uppercase;
    background-color: $c--white;
    transition: top 0.3s ease-in-out;
    border-bottom: 1px solid $c--black;

    .nav-link {
      color: $c--tundora;
      padding: 0.35rem 0 0;

      &:hover,
      &.active {
        color: $primary;
        font-weight: $font-weight-base;
      }

      &:not(:last-child) {
        margin-right: 2.5rem
      }
    }
  }

  .nav-icon-search {
    &:before {
      content: '';
      width: 1.375rem;
      height: 1.188rem;
      display: inline-block;
      margin-right: 0.438rem;
      vertical-align: middle;
      background: url('../img/menu/search_desktop.svg') no-repeat;
      background-size: contain;
    }
  }
}
