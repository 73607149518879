.home {
  // sekcje
  section {
    .btn-outline-primary {
      @include archivo-bold;
      padding: 8px 22px;
      margin: $margin-42 auto 0;

      @include media-breakpoint-up(sm) {
        margin: $margin-56 auto 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &.week-top {
      margin-bottom: 2.5rem;
      @include media-breakpoint-up(sm) {
        margin-bottom: 3.438rem;
      }

      .card {
        figure {
          margin: 0;
        }

        img {
          @include media-breakpoint-down(lg) {
            width: 100%
          }
          margin-bottom: $margin-12
        }

        &.promoted {
          padding-bottom: $margin-24;
          margin-bottom: $margin-12;
          border-bottom: $grid-border-style;

          @include media-breakpoint-up(sm) {
            padding-bottom: $margin-32;
            margin-bottom: $margin-16;
          }

          h1 {
            margin-top: 0.5rem;
            margin-bottom: $margin-12;
            padding-bottom: $margin-12;
            border-bottom: $grid-border-style;

            @include media-breakpoint-up(sm) {
              margin-top: 0;
              margin-bottom: $margin-16;
              padding-bottom: $margin-16;
            }
          }

          a {
            display: inline-block;

            &.editorial {
            }
          }

          .h3 {
            display: block;
            margin-bottom: $margin-12;

            @include media-breakpoint-up(sm) {
              margin-bottom: $margin-16;
            }
          }

          a {
            display: block;
            margin-bottom: $margin-18;

            @include media-breakpoint-up(sm) {
              margin-bottom: $margin-16;
            }
          }
        }

        &.grid {
          .h5,
          .author-list {
            display: block;
            margin-bottom: $margin-12;
            @include media-breakpoint-up(sm) {
              margin-bottom: $margin-16;
            }
          }

          .item {
            display: flex;
            padding-bottom: 1rem;
            margin-bottom: 0.875rem;
            flex-direction: row-reverse;
            justify-content: space-between;
            border-bottom: $grid-border-style;

            @include media-breakpoint-up(sm) {
              border-bottom: 0;
              margin-bottom: 0;
              //padding-bottom: 55px;
              flex-direction: column;
            }

            picture {
              margin: 0;

              img {
                width: 120px;
                max-width: max-content;
                margin: 0 0 0 0.875rem;
                @include media-breakpoint-up(sm) {
                  width: 100%;
                  max-width: initial;
                  margin: 0 0 0.625rem 0;
                }
              }
            }

            // border - desktop only
            @include media-breakpoint-up(sm) {
              &:not(:last-of-type) {
                padding-bottom: 55px;
                margin-bottom: 0.938rem;
                border-bottom: $grid-border-style;
              }
            }
          }

          // more, right column
          .more {
            // desktop only
            @include media-breakpoint-up(sm) {
              border-left: $grid-border-style
            }

            .item {
              img {
                display: block;
                @include media-breakpoint-up(sm) {
                  display: none;
                }
              }

              &:last-child {
                border: 0;
                margin: 0;
                padding: 0;
              }
            }
          }

          &.grid-1 {
            p {
              display: none;

              @include media-breakpoint-up(sm) {
                display: block;
              }
            }

            img {
              margin: 0;
            }
          }

          &.grid-2 {
            .item {
              border: 0;
            }

            .bordered {
              @include media-breakpoint-up(sm) {
                border-right: $grid-border-style;
              }

              .item {
                &:first-of-type {
                  padding-bottom: $margin-24;
                  @include media-breakpoint-up(sm) {
                    padding: 0;
                  }
                }

                border-bottom: $grid-border-style;
                @include media-breakpoint-up(sm) {
                  border: 0;
                }
              }
            }
          }

          &.grid-3 {
          }

          &.grid-4 {
            .item {
              @include media-breakpoint-up(sm) {
                height: 100%;
                padding-bottom: 0;
              }

              figure,
              img {
                display: block;
                @include media-breakpoint-up(sm) {
                  display: none;
                }
              }
            }

            .mobile {
              @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: inherit;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 16px;
              }
            }
          }
        }
      }

      .sidebar {
        hr {
          margin: 0 0 1rem;
        }

        @include media-breakpoint-up(sm) {
          border-left: $grid-border-style;
        }

        .glide {
          .glide__bullets {
            @include media-breakpoint-up(sm) {
              display: none;
            }
          }

          .glide__track {
            display: grid;
            @include media-breakpoint-up(sm) {
              display: block;
            }
          }

          // slides
          .glide__slide {
            padding-right: 0.875rem;
            margin-bottom: $margin-12;
            border-right: $grid-border-style;

            @include media-breakpoint-up(sm) {
              border-right: 0;
              padding-right: 0;
              padding-bottom: $margin-16;
              border-bottom: $grid-border-style;
            }

            &:last-child {
              border-right: 0;
            }

            img {
              @include media-breakpoint-down(lg) {
                width: 100%;
              }
              display: block;
              margin-bottom: $margin-12;
            }

            // hide on desktop
            @include media-breakpoint-up(sm) {
              &:nth-child(n+2) {
                img {
                  display: none;
                }
              }
            }

            .h5 {
              display: block;
              margin-bottom: $margin-18;
              @include media-breakpoint-up(sm) {
                margin-bottom: $margin-12;
              }
            }
          }
        }
      }
    }

    &.opinions {
      h6 {
        color: $c--silver-chalice;
        @include media-breakpoint-up(sm) {
          margin-top: $margin-12;
        }
      }

      img {
        background-color: $c--malibu;
      }

      .h5 {
        margin-bottom: $margin-12;

        @include media-breakpoint-up(sm) {
          margin-top: $margin-12;
        }
      }

      a {
        &.author-list {
          @include media-breakpoint-up(sm) {
            margin-top: $margin-16;
          }
        }
      }

      p.lead {
        display: none;
        margin: 0 0 30px;

        @include media-breakpoint-up(sm) {
          display: block;
          margin-top: $margin-16;
          margin-bottom: $margin-16;
        }
      }

      .btn-outline-primary {
        margin-top: 0;
        @include media-breakpoint-up(sm) {
          margin-top: $margin-56;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .glide__type--slide-to-row {
        .glide__track {
          @include media-breakpoint-up(sm) {
            border-left: $grid-border-style;
            border-right: $grid-border-style;
          }
        }

        .glide__slides {
          margin-bottom: $margin-6;

          .glide__slide {
            margin-bottom: 12px;
            padding-bottom: 24px;
            border-bottom: $grid-border-style;
            @include media-breakpoint-up(sm) {
              margin: 0;
              height: auto;
              padding: 0 12px;
              border-bottom: 0;
              align-items: start;
              border-right: $grid-border-style;
            }

            &:nth-child(n+4),
            &:last-of-type {
              border-bottom: 0;
            }

            &:nth-child(n+5) {
              display: none;
              @include media-breakpoint-up(sm) {
                display: flex;
              }
            }
          }
        }

        .glide__arrows {
          .glide__arrow {
            top: 50px;
          }
        }
      }
    }

    &.video-cast {
      .glide {
        @include media-breakpoint-up(sm) {
          padding: $margin-24;
          background-color: $c--resolution-blue;
        }

        .glide__track {
          display: grid;
        }

        .glide__bullets {
          display: inline-flex;
          margin-top: $margin-24;
          @include media-breakpoint-up(sm) {
            display: none;
          }
        }

        .glide__slide {
          padding-right: 14px;
          border-right: $grid-border-style;
          @include media-breakpoint-up(sm) {
            border-right: 0;
            padding-right: 0;
          }

          &:last-child {
            border-right: 0;
          }

          .video {
            width: 100%;
            max-width: 450px;
            position: relative;

            img {
              width: 100%;
              height: auto;
              display: block;
              @include media-breakpoint-up(sm) {
                border: 2px solid $c--white;
              }
            }

            .icon {
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);

              &:hover {
                cursor: pointer;
              }

              i {
                opacity: 1;
                display: block;
                width: 4.375rem;
                height: 4.375rem;
                transition: all 200ms;
                background: url('../img/video_play.svg');

                &:hover {
                  opacity: 0.85;
                }
              }
            }
          }

          .content {
            height: 100%;
            padding: 0 16px 60px 16px;
            background-color: $c--resolution-blue;
            @include media-breakpoint-up(sm) {
              padding: 0;
            }

            h5.title {
              color: $c--malibu;
              padding-top: $margin-12;
              padding-bottom: $margin-6;

              @include media-breakpoint-up(sm) {
                padding-top: 0;
                margin-top: $margin-16;
                margin-bottom: $margin-12;
                padding-bottom: $margin-12;
              }
            }

            .h5 {
              color: $c--white;
            }

            ul {
              padding: 0;
              list-style: none;
              margin: $margin-24 0 0;

              li {
                line-height: 1.2;

                a {
                  color: $c--malibu;
                }
              }
            }
          }
        }

        @include media-breakpoint-up(sm) {
          .btn-outline-primary {
            margin: $margin-56 auto 0;
            color: $c--white;
            border-color: $c--white;
            outline-color: $c--white;

            &:hover, &:focus {
              cursor: pointer;
              outline-offset: 0;
              outline: solid 2px;
              color: darken($c--white, 15);
              background: $c--resolution-blue;
              border-color: darken($c--white, 15);
            }
          }
        }
      }
    }

    &.culture {
      // borders
      .item {
        @include media-breakpoint-down(lg) {
          // remove margin of first paragraph
          p {
            margin-bottom: 0;
            @include media-breakpoint-up(sm) {
              margin-bottom: inherit;
            }
          }

          &:nth-child(3),
          &:nth-child(7),
          &:nth-child(10) {
            border-right: $grid-border-style;
          }
        }

        // desktop
        @include media-breakpoint-up(sm) {
          border-right: $grid-border-style;

          &:nth-child(3),
          &:nth-child(10) {
            border-right: 0;
          }
        }
      }

      .border-separator {
        width: 100%;
        height: 1px;
        margin: 16px 0;
        padding: 0 16px;
        background: $c--silver;

        @include media-breakpoint-up(sm) {
          margin: 40px 0 20px;
        }
      }

      img {
        width: 100%;
        margin-bottom: $margin-12;
      }

      .author-list {
        display: block;
        margin-bottom: $margin-12;

        @include media-breakpoint-up(sm) {
          margin-bottom: $margin-16;
        }
      }

      a {
        &.h5 {
          display: inline-block;
          margin-bottom: $margin-12;
          @include media-breakpoint-up(sm) {
            margin-bottom: $margin-16;
          }

          span {
            color: $primary;
          }

          &.h5-override-mobile {
            font-size: $font-size-18;
            line-height: $font-size-26;
          }
        }
      }

      p.lead {
        margin-bottom: $margin-8;
        @include media-breakpoint-up(sm) {
          margin-bottom: $margin-16;
        }
      }

      .btn-outline-primary {
        margin-bottom: 0;
      }
    }

    &.abroad {
      .glide {
        .glide__bullets {
          display: inline-flex;
          margin-top: $margin-24;
          @include media-breakpoint-up(sm) {
            display: none;
          }
        }

        .glide__track {
          display: grid;
        }

        .glide__slide {
          position: relative;

          &::before {
            height: 100%;
            right: -10px;
            content: '';
            display: block;
            position: absolute;
            border-right: $grid-border-style;

            @include media-breakpoint-up(sm) {
              right: -20px;
            }
          }

          // hide moar than 3 on desktop
          &:nth-child(n+4) {
            display: list-item;
            @include media-breakpoint-up(sm) {
              display: none;
            }
          }

          // borders
          &:last-of-type {
            border-right: none;

            &::before {
              border-right: none;
            }
          }

          img {
            @include media-breakpoint-down(lg) {
              width: 100%;
            }
          }

          .content {
            height: 100%;
            padding: 12px 16px 36px;
            background-color: $c--wild-sand;

            @include media-breakpoint-up(sm) {
              padding: 16px 20px 32px;
            }

            h5 {
              color: $primary;
              margin-bottom: $margin-12;

              @include media-breakpoint-up(sm) {
                margin-bottom: $margin-16;
              }
            }

            .h5 {
              color: $c--black;
              margin-bottom: $margin-12;
              @include media-breakpoint-up(sm) {
                margin-bottom: $margin-24;
              }
            }

            a {
              display: inline-block;
            }
          }
        }
      }
    }

    &.publishing {
      .glide {
        margin-top: 17px;
        @include media-breakpoint-up(sm) {
          margin-top: 22px
        }

        .glide__arrows {
          display: none;
          @include media-breakpoint-up(sm) {
            display: block;
          }

          .glide__arrow {
            top: 230px;
          }
        }

        .glide__bullets {
          margin-bottom: 0;
          display: inline-flex;
          margin-top: $margin-24;

          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
      }

      h6 {
        @include archivo-bold;
        margin: 0;
        padding: 5px 0;
        color: $primary;
        min-height: 26px;
        font-size: $font-size-14;
        text-transform: uppercase;

        &.new {
          color: $c--white;
          padding: 5px 10px;
          display: inline-block;
          background-color: $primary;
        }
      }

      .content {
        padding-top: 9px;
        border-top: $grid-border-style;

        @include media-breakpoint-up(sm) {
          padding-top: 12px;
        }

        img {
          height: 360px;
          display: block;
          margin: 0 auto $margin-12;

          @include media-breakpoint-up(sm) {
            height: 425px;
            margin-bottom: $margin-16;
          }
        }

        a {
          color: $primary;
          display: inline-block;

          &:hover {
            color: darken($primary, 15);
          }

          &.h5 {
            display: block;
            color: $c--black;
            margin-bottom: $margin-12;

            &:hover {
              color: darken($c--black, 15);
            }

            @include media-breakpoint-up(sm) {
              margin-bottom: $margin-12;
            }
          }

          &.btn-primary {
            @include archivo-bold;
            display: table;
            color: $c--white;
            padding: 6px 28px;
            margin: $margin-18 auto 0;
            text-transform: uppercase;

            @include media-breakpoint-up(sm) {
              margin-left: 0;
              margin-top: $margin-24;
            }
          }
        }
      }
    }

    &.events {
      img {
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        margin-bottom: $margin-12;
        @include media-breakpoint-up(sm) {
          margin-bottom: $margin-16;
        }
      }

      small {
        @include archivo-bold;
        display: block;
        color: $primary;
        margin-bottom: $margin-6;
        @include media-breakpoint-up(sm) {
          margin-bottom: $margin-8;
        }
      }

      a {
        display: inline-block;

        &.place {
        }
      }

      .h5, h5 {
        display: block;
        margin-bottom: $margin-24;
        @include media-breakpoint-up(sm) {
          margin-bottom: $margin-16;
        }
      }
    }

    &.projects {
      .glide {
        .glide__slide {
          margin-bottom: 24px;
          @include media-breakpoint-up(sm) {
            margin-bottom: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(n+4) {
            display: none;
            @include media-breakpoint-up(sm) {
              display: inline-flex;
            }
          }

          @include media-breakpoint-down(lg) {
            img {
              width: 100%;
            }
          }
        }

        .glide__arrows {
          display: none;
          @include media-breakpoint-up(sm) {
            display: block;
          }

          .glide__arrow {
            top: 70px;
          }
        }
      }
    }
  }
}
