.nav-mobile {
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 1060;
  position: fixed;
  height: 4.063rem;
  background-color: $c--white;
  border-top: $grid-border-style;

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;

    &.nav-icon {
      width: 3.063rem;
      height: 3.063rem;
      text-indent: -999rem;

      &.nav-icon-menu {
        background: url('../img/menu/menu.svg') center bottom no-repeat;
      }

      &.nav-icon-search {
        background: url('../img/menu/search.svg') center bottom no-repeat;
      }

      &.nav-icon-logo {
        background: url('../img/menu/logotype.svg') center center no-repeat;
      }

      &.nav-icon-newsletter {
        background: url('../img/menu/newsletter.svg') center bottom no-repeat;
      }

      &.nav-icon-support {
        background: url('../img/menu/support.svg') center bottom no-repeat;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.offcanvas {
  @include media-breakpoint-up(sm) {
    display: none;
  }

  &.show-desktop {
    display: flex;
  }

  .btn-close {
    border: 0;
    top: 0.938rem;
    right: 0.938rem;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    background: url('../img/menu/close.svg') no-repeat;
    background-size: contain;
  }

  .offcanvas-body {
    padding-bottom: 4.375rem;
  }
}

.offcanvas-mobile-menu {
  .btn-support {
    @include archivo-bolder;
    color: $c--white;
    margin-top: 2rem;
  }

  ul {
    margin-top: 2.188rem;

    li {
      margin-bottom: 2.188rem;

      a {
        @include archivo-bold;
        color: $c--black;
        font-size: $font-size-base;
      }
    }
  }
}

.offcanvas-desktop-search,
.offcanvas-mobile-search {
  .offcanvas-body {
    display: flex;
    flex-direction: column;

    h1 {
      color: $c--alto;
      margin-bottom: 2rem;
      font-size: $font-size-36;
      line-height: $font-size-42;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-30;
        line-height: $font-size-40;
      }
    }

    input {
      @include archivo-regular;
      width: 100%;
      height: 3.438rem;
      border-radius: 0;
      color: $c--black;
      margin-bottom: 2.313rem;
      font-size: $font-size-16;
      line-height: $font-size-22;
      padding: 0 2.5rem 0 0.938rem;
      border: 0.313rem solid $c--black;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }

      &::placeholder,
      &::-ms-input-placeholder {
        color: $c--black;
        opacity: 1; // firefox
      }

      &:focus {
        outline: 0;
      }
    }

    .group-btn-inside {
      width: 100%;
      position: relative;
    }

    .btn-submit {
      z-index: 2;
      top: 1.05rem;
      height: 1.5rem;
      display: block;
      width: 1.875rem;
      right: 0.875rem;
      position: absolute;
      transform: translateX(2px);
      background: url('../img/menu/search.svg') no-repeat;
    }
  }
}

.offcanvas {
  &.offcanvas-mobile-search {
    .offcanvas-body {
      justify-content: flex-end;
    }
  }

  &.offcanvas-desktop-search {
    width: 400px;

    .offcanvas-body {
      padding: 75px 25px;
      justify-content: flex-start;

      h1 {
        margin-top: 35px;
      }
    }
  }
}
