@mixin sidebar-left-layout {
  .sidebar {
    @include media-breakpoint-up(lg) {
      margin-right: 40px;
      padding-right: 25px;
      border-right: $grid-border-style;
    }

    h1 {
      margin-bottom: $margin-12;

      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-16;
      }
    }

    p {
      color: $c--tundora;
      margin-bottom: 25px;
      padding-bottom: 25px;
      font-size: $font-size-17;
      line-height: $font-size-28;

      @include media-breakpoint-up(lg) {
        margin-bottom: inherit;
        padding-bottom: inherit;
      }

      &:last-of-type {
        border-bottom: $grid-border-style;
        @include media-breakpoint-up(lg) {
          border-bottom: 0;
          margin-bottom: inherit;
          padding-bottom: inherit;
        }
      }
    }

    hr {
      margin: $margin-12 0;
      @include media-breakpoint-up(lg) {
        margin: $margin-16 0;
      }
    }

    .sidebar-content {
      margin-bottom: $margin-24;
      padding-bottom: $margin-24;
      border-bottom: $grid-border-style;

      @include media-breakpoint-up(lg) {
        margin: 0;
        border: 0;
        padding: 0;
      }

      p {
        padding-bottom: 0;
        color: $c--tundora;
        margin-bottom: 25px;
        font-size: $font-size-17;
        line-height: $font-size-28;

        &:last-of-type {
          border-bottom: 0;
        }
      }

      .btn {
        display: block;
        padding-left: 2rem;
        padding-right: 2rem;
        margin: 30px auto 0;
      }
    }
  }
}

@mixin category-articles-layout {
  .articles {
    .item {
      @include media-breakpoint-up(lg) {
        &:nth-child(1),
        &:nth-child(2) {
          border-right: $grid-border-style;
        }
      }
    }

    img {
      margin-bottom: $margin-12;
    }

    .h5 {
      font-size: $font-size-18;
      margin-bottom: $margin-12;
      line-height: $font-size-26;

      span {
        color: $primary;
      }
    }

    a {
      display: inline-block;

      &.author-list {
        margin-bottom: $margin-24;
        @include media-breakpoint-up(lg) {
          margin-bottom: $margin-16;
        }

        &.no-margin-mobile {
          margin-bottom: 0;
          @include media-breakpoint-up(lg) {
            margin-bottom: $margin-16;
          }
        }
      }
    }

    .author-list {
      margin-bottom: $margin-24;
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-16;
      }

      &.no-margin-mobile {
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
          margin-bottom: $margin-16;
        }
      }
    }

    h6, .h6 {
      @include archivo-regular;
      margin-bottom: $margin-12;
      font-size: $font-size-16;
      color: $c--silver-chalice;
      line-height: $font-size-22;
    }

    .border-bottom {
      display: block;
      margin: 0 0 $margin-12;
      @include media-breakpoint-up(lg) {
        display: none;
        margin: $margin-16 0;
        border-bottom: $grid-border-style;
      }
    }

    ul {
      &.list-unstyled {
        li {
          a {
            margin-bottom: $margin-24;
            @include media-breakpoint-up(lg) {
              margin-bottom: $margin-16;
            }
          }
        }
      }
    }
  }
}

@mixin three-cols-with-borders {
  .articles {
    .item {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        border-right: $grid-border-style;
        &:nth-child(3n) {
          border-right: 0;
        }
      }

      .box {
        flex-grow: 1;
        display: flex;
        margin-bottom: 14px;
        flex-direction: column;
        border-bottom: $grid-border-style;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
          border-bottom: 0;
        }

        .content {
          width: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          padding-top: $margin-12;
          padding-bottom: $margin-36;

          @include media-breakpoint-up(lg) {
            padding-top: $margin-16;
            padding-bottom: $margin-32;
          }

          .title {
            padding: 0;
            margin: 0 0 $margin-12;
            color: $c--science-blue;
          }

          a {
            color: $primary;

            &.event-info {
              margin-bottom: $margin-12;

              &:hover {
                cursor: initial;
                text-decoration: none;
              }
            }

            &.h5 {
              color: $c--black;
            }
          }

          &.with-background {
            min-height: 200px;
            padding-left: 28px;
            padding-right: 28px;
            margin-bottom: 14px;
            background-color: $c--wild-sand;

            @include media-breakpoint-up(lg) {
              margin-bottom: 0;
              border-bottom: 0;
              padding: 14px 19px 19px;
            }

            h5 {
              @include media-breakpoint-up(lg) {
                margin: 0 0 20px 0;
              }
            }
          }

          .topic-number {
            @include archivo-regular;
            margin-top: auto;
            color: $c--silver-chalice;

            @include media-breakpoint-up(lg) {
              display: none;
            }
          }

          .author-list {
            color: $c--malibu;
            display: inline-block;
            font-size: $font-size-16;
            line-height: $font-size-22;

            margin-bottom: $margin-36;
            @include media-breakpoint-up(lg) {
              margin-bottom: $margin-24;
            }
          }
        }
      }
    }

    .border-bottom {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        margin: $margin-32 0 $margin-16;
      }
    }
  }
}
