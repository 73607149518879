.pagination {
  margin-top: $margin-42;
  @include media-breakpoint-up(lg) {
    margin-top: $margin-56;
  }

  .page-item {
    margin: 0 2px;

    @include media-breakpoint-up(lg) {
      margin: 0 8px;
    }

    &.dots {
      display: none;
      @include media-breakpoint-up(lg) {
        margin: 0;
        display: block;
      }
    }
  }

  .page-link {
    @include archivo-bold;
    font-size: $font-size-18;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-15;
    }

    &.bordered,
    &.current {
      border-width: 2px;
      border-color: $primary;
    }

    &:hover {
      border-width: 2px;
    }

    &.dots {
      padding-left: 0;
      padding-right: 0;

      &:hover {
        cursor: default;
        border-color: $c--white;
      }
    }
  }
}
