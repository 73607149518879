body {
  @include merriweather-regular;
  color: $c--black;
  font-size: $font-size-16;
  line-height: $font-size-22;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @include merriweather-bolder;
  @include media-breakpoint-up(sm) {
    @include merriweather-bold;
  }
}

h1, .h1 {
  font-size: $font-size-24;
  line-height: $font-size-34;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-30;
    line-height: $font-size-40;
  }
}

h2, .h2 {
  font-size: $font-size-20;
  line-height: $font-size-30;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-24;
    line-height: $font-size-32;
  }
}

h3, .h3 {
  font-size: $font-size-18;
  line-height: $font-size-26;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-22;
    line-height: $font-size-32;
  }
}

h4, .h4 {
  font-size: $font-size-16;
  line-height: $font-size-23;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-20;
    line-height: $font-size-26;
  }
}

h5, .h5 {
  font-size: $font-size-16;
  line-height: $font-size-23;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-18;
    line-height: $font-size-26;
  }
}

h6, .h6 {
  font-size: $font-size-15;
  line-height: $font-size-20;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-16;
    line-height: $font-size-22;
  }
}

a {
  @include archivo-bold;

  color: $primary;
  font-size: $font-size-16;
  line-height: $font-size-22;
  transition: all 200ms ease, all 200ms ease;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    // color: lighten($primary, 15);
  }

  &.author-list {
    display: block;
  }
}

p,
dl,
ol {
  font-size: $font-size-17;
  line-height: $font-size-28;

  @include media-breakpoint-up(sm) {
    line-height: $font-size-30;
  }
}

p {
  &.lead {
    margin: 0;
    padding: 0;
    color: $c--tundora;
    font-size: $font-size-15;
    line-height: $font-size-24;
  }
}

blockquote {
  @include merriweather-bold;
  padding-top: 0;
  font-size: $font-size-20;
  line-height: $font-size-28;

  @include media-breakpoint-up(lg) {
    margin-top: -25px;
    margin-bottom: 60px;
    font-size: $font-size-24;
    line-height: $font-size-36;
  }

  &:before {
    top: -10px;
    width: 35px;
    display: block;
    position: relative;
    content: url('../img/icons/blockquote-mobile.svg');
    @include media-breakpoint-up(lg) {
      top: 18px;
      left: -68px;
      width: 34px;
      content: url('../img/icons/blockquote.svg');
    }
  }
}
