.publishing-page {
  @include sidebar-left-layout;

  h1 {
    margin-bottom: $margin-24;
    @include media-breakpoint-up(sm) {
      margin-bottom: $margin-16;
    }
  }

  main {
    p,
    dl,
    ol {
      font-size: $font-size-17;
      @include media-breakpoint-up(sm) {
        line-height: $font-size-28;
      }
      line-height: $font-size-30;
    }

    .sidebar {
      a {
        color: $c--black;
        font-size: $font-size-17;
        text-decoration: underline;

        &:hover {
          color: $c--black;
        }
      }

      p {
        margin-bottom: 25px;
      }
    }

    .content {
      padding: 0 0 $margin-16;

      .btn-primary {
        @include archivo-bold;
        color: $c--white;
        font-size: $font-size-15;
        text-transform: uppercase;
      }

      h4.post-type {
        @include archivo-bold;

        color: $primary;
        margin-bottom: 0;
        font-size: $font-size-14;
        text-transform: uppercase;
        line-height: $font-size-32;

        &.new {
          color: $c--white;
          padding: 3px 5px;
          margin-bottom: -1px;
          display: inline-block;
          line-height: $font-size-26;
          background-color: $primary;
        }
      }

      .publication-content {
        padding-top: 10px;
        border-top: $grid-border-style;
      }

      h5, .h5 {
        font-size: $font-size-18;
        line-height: $font-size-26;
        margin-bottom: $margin-12;
        @include media-breakpoint-up(sm) {
          margin-bottom: $margin-16;
        }
      }

      // single
      &.single {
        padding: 0 $margin-24;

        @include media-breakpoint-up(lg) {
          padding: 0;
        }

        .featured-image {
          margin-bottom: $margin-24;
          @include media-breakpoint-up(lg) {
            margin-bottom: $margin-32;
          }
        }

        article {
          padding-left: $margin-16;
          padding-right: $margin-16;

          @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
          }

          h1 {
            font-size: $font-size-18;
            line-height: $font-size-26;
            margin-bottom: $margin-12;
            @include media-breakpoint-up(lg) {
              font-size: $font-size-30;
              margin-bottom: $margin-16;
              line-height: $font-size-40;
            }
          }

          .publication-content {
            .authors {
              margin-bottom: $margin-24;
              @include media-breakpoint-up(lg) {
                margin-bottom: $margin-16;
              }
            }

            .btn-primary {
              width: 100%;
              display: block;
              padding: 10px 25px;
              margin-bottom: $margin-16;
              @include media-breakpoint-up(lg) {
                width: 275px
              }

              &:last-of-type {
                margin-bottom: $margin-36;
                @include media-breakpoint-up(lg) {
                  display: inline-block;
                  margin-bottom: $margin-32;
                }
              }
            }
          }
        }
      }

      // listing
      &.listing {
        .articles {
          @include media-breakpoint-up(lg) {
            margin-bottom: $margin-32;
            padding-bottom: $margin-32;
            border-bottom: $grid-border-style;
          }

          .items {
            &::after {
              content: '';
              display: block;
              margin-bottom: $margin-24;
              border-bottom: $grid-border-style;
            }

            @include media-breakpoint-up(lg) {
              &::after {
                display: none;
              }
            }

            article {
              padding-left: 25px;
              padding-right: 25px;
              margin-bottom: $margin-36;

              @include media-breakpoint-up(lg) {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;

                &:after {
                  display: none;
                }
              }

              .publication-content {
                &.normal {
                  margin-top: 32px;
                }

                .btn-primary {
                  padding: 10px 25px;
                }

                img {
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: $margin-24;
                  @include media-breakpoint-up(md) {
                    margin-bottom: $margin-16;
                    height: 470px;
                  }
                }

                .authors {
                  margin: $margin-12 0 $margin-24;

                  @include media-breakpoint-up(md) {
                    margin: $margin-12 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-content {
    p {
      margin-bottom: $margin-36;
    }
  }
}
