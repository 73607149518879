@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

/////////////////////////
// Archivo
/////////////////////////

@mixin archivo-regular {
  font-style: normal;
  font-weight: 400;
  font-optical-sizing: auto;
  font-family: "Archivo", sans-serif;
  font-variation-settings: "wdth" 100;
}

.archivo-regular {
  @include archivo-regular;
}

@mixin archivo-bold {
  font-style: normal;
  font-weight: 700;
  font-optical-sizing: auto;
  font-family: "Archivo", sans-serif;
  font-variation-settings: "wdth" 100;
}

.archivo-bold {
  @include archivo-bold;
}

@mixin archivo-bolder {
  font-style: normal;
  font-weight: 900;
  font-optical-sizing: auto;
  font-family: "Archivo", sans-serif;
  font-variation-settings: "wdth" 100;
}

.archivo-bolder {
  @include archivo-bolder;
}

@mixin archivo-regular-italic {
  font-style: italic;
  font-weight: 400;
  font-optical-sizing: auto;
  font-family: "Archivo", sans-serif;
  font-variation-settings: "wdth" 100;
}

.archivo-regular-italic {
  @include archivo-regular-italic;
}

@mixin archivo-bold-italic {
  font-style: normal;
  font-weight: 700;
  font-optical-sizing: auto;
  font-family: "Archivo", sans-serif;
  font-variation-settings: "wdth" 100;
}

.archivo-bold-italic {
  @include archivo-bold-italic;
}

/////////////////////////
// Merriweather
/////////////////////////

@mixin merriweather-regular {
  font-style: normal;
  font-weight: 400;
  font-family: "Merriweather", serif;
}

.merriweather-regular {
  @include merriweather-regular;
}

@mixin merriweather-bold {
  font-style: normal;
  font-weight: 700;
  font-family: "Merriweather", serif;
}

.merriweather-bold {
  @include merriweather-bold;
}

@mixin merriweather-bolder {
  font-style: normal;
  font-weight: 900;
  font-family: "Merriweather", serif;
}

.merriweather-bolder {
  @include merriweather-bolder;
}

@mixin merriweather-regular-italic {
  font-style: italic;
  font-weight: 400;
  font-family: "Merriweather", serif;
}

.merriweather-regular-italic {
  @include merriweather-regular-italic;
}

@mixin merriweather-bold-italic {
  font-style: italic;
  font-weight: 700;
  font-family: "Merriweather", serif;
}

.merriweather-bold-italic {
  @include merriweather-bold-italic;
}
