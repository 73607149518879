.event-single {
  @include sidebar-left-layout;

  p {
    font-size: $font-size-base;

    &.event-info {
      @include archivo-bold;
      margin: 0;
      padding: 0;
      color: $primary;

      &.event-date {
        margin-bottom: $margin-6;
        @include media-breakpoint-up(sm) {
          margin-bottom: $margin-8;
        }
      }
    }
  }

  .content {
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }

    .featured-image {
      margin-bottom: $margin-24;
      @include media-breakpoint-up(lg) {
        margin-bottom: $margin-32;
      }
    }
  }
}
